import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import styled, { ThemeProvider } from "styled-components";
import theme, { appColors } from "../themes/default";
import Header from "./header";
import Footer from "./footer";
import "./layout.css";
import "typeface-open-sans-condensed";
import "../themes/bodoniXT/index.css";
import "../themes/bodoni/index.css";

const Main = styled.main`
  flex: 1 0 auto;
  min-height: 75vh;
  margin: 0 1rem;
  font-size: 16px;
`;
const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query PostLayout {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <>
          {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 1180,
              paddingTop: 0,
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Main>{children}</Main>
            <Footer />
          </div>
        </>
      </ThemeProvider>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
