import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link as link } from "gatsby";
import kebabCase from "lodash/kebabCase";
import Img from "gatsby-image";
import {SectionHeader, CoolHeader} from './Elements/CoolHeader'

import Subline from "./Subline";

const Post = styled.article`
  display: flex;
  flex-direction: column;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

const Title = styled.h2`
font-family: Open Sans Condensed, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  font-weight: bold;
text-transform: uppercase;
  font-weight: bold;
  position: relative;
  text-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  ${'' /* margin-bottom: 0.75rem; */}
  a {
    color: black;
    &:hover {
      color: ${props => props.theme.colors.secondary};
    }
  }
`;

const Initiale = styled.span`
  position: absolute;
  font-size: 7rem;
  transform: translate(-25%, -50%);
  opacity: 0.08;
  user-select: none;
  z-index: -1;
  color: ${props => props.color};
`;

const Excerpt = styled.p`
  grid-column: -1 / 1;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Link = styled(link)`
text-decoration: none;`;

const Article = ({
  title,
  date,
  excerpt,
  slug,
  contributors,
  primaryColor,
  bannerImage,
  shortDescription,
  issueNumber
}) => {
  const firstChar = title.charAt(0);

  return (
    <Post>
      <Title>
        {/* <Initiale color={primaryColor}>{contributors[0].split(" ")[0]}</Initiale> */}
        <Link to={slug}>
          {/* <Img fluid={bannerImage} /> */}
          {issueNumber}{": "}{title}
        </Link>
      </Title>
      <Subline>
        {date} | Contributors:{" "}
        {contributors.map((contributor, i) => (
          <React.Fragment key={contributor}>
            {!!i && ", "}
            {contributor.toLowerCase()}
            {/* <Link to={`/contributors/${kebabCase(contributor)}`}>{contributor}</Link> */}
          </React.Fragment>
        ))}
      </Subline>
      <Excerpt>{shortDescription}</Excerpt>
    </Post>
  );
};

export default Article;

Article.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  contributors: PropTypes.array.isRequired,
  primaryColor: PropTypes.string,
  bannerImage: PropTypes.object,
  shortDescription: PropTypes.string
};
