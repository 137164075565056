import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import PostLayout from '../components/PostLayout';
import Layout from '../components/layout'
import Header from "../components/header";

import {
  // Layout,
  Wrapper,
  HeaderNew,
  Article,
  
} from "../components";
import {SectionHeader, CoolHeader} from '../components/Elements/CoolHeader'
import config from "../../config";

const Content = styled.div`
  grid-column: 2;
  box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 2rem 4rem;
  background-color: ${props => props.theme.colors.bg};
  z-index: 9000;
  margin-top: -3rem;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 3rem 3rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1.5rem;
  }
`;

const Journal = ({ data: { allMdx } }) => {
  const { nodes, totalCount } = allMdx;

  let published = nodes.filter(post => post.frontmatter.published);
  let unPublished = nodes.filter(post => !post.frontmatter.published);

  return (
    <Layout>
      {/* <Wrapper> */}
        <Helmet title={`Journal Issues | ${config.siteTitle}`} />
        {/* <HeaderNew>
          <Link to="/">{config.siteTitle}</Link>
        </HeaderNew> */}
        {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
        {/* <Header siteTitle={"Pomme Journal"} /> */}
        {/* <Content> */}
          <CoolHeader>Published Journals </CoolHeader>
          {published.map(post => (
            <Article
              title={post.frontmatter.title}
              date={post.frontmatter.callForEntriesCloseDay}
              excerpt={post.excerpt}
              shortDescription={post.frontmatter.shortDescription}
              slug={post.fields.slug}
              contributors={post.frontmatter.contributors}
              key={post.fields.slug}
              primaryColor={post.frontmatter.primaryColor}
              bannerImage={post.frontmatter.banner.childImageSharp.fluid}
              issueNumber={post.frontmatter.issueNumber}
            />
          ) )}
          {unPublished.length > 0 &&<CoolHeader>Upcoming Journals </CoolHeader>}
          {unPublished.map(post => (
            <Article
              title={post.frontmatter.title}
              date={post.frontmatter.callForEntriesOpenDate}
              excerpt={post.excerpt}
              shortDescription={post.frontmatter.shortDescription}
              slug={post.fields.slug}
              contributors={post.frontmatter.contributors}
              key={post.fields.slug}
              primaryColor={post.frontmatter.primaryColor}
              bannerImage={post.frontmatter.banner.childImageSharp.fluid}
              issueNumber={post.frontmatter.issueNumber}
            />
          ) )}
        {/* </Content> */}
      {/* </Wrapper> */}
    </Layout>
  );
};

export default Journal;

Journal.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired
    })
  }).isRequired
};

export const postQuery = graphql`
  query journalPage {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      nodes {
        frontmatter {
          title
          date(formatString: "MM/DD/YYYY")
          callForEntriesOpenDate(formatString: "MM/DD/YYYY")
          callForEntriesCloseDay(formatString: "MM/DD/YYYY")
          contributors
          primaryColor
          shortDescription
          issueNumber
          published
          banner {
            ...bannerImage640
          }
        }
        fields {
          slug
        }
        excerpt(pruneLength: 200)
      }
    }
  }
`;
// export const IndexQuery = graphql`
//   query IndexQuery {
//     allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
//       nodes {
//         excerpt(pruneLength: 200)
//         timeToRead
//         fields {
//           slug
//         }
//         frontmatter {
//           title
//           date(formatString: "MM/DD/YYYY")
//           categories
//           primaryColor
//           banner {
//             ...bannerImage640
//           }
//         }
//       }
//     }
//   }
// `;
